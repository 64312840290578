import { Link } from "gatsby";
import React from "react";
import Layout from "../components/Layout";

export default function ErrorPage() {
  return (
    <Layout
      title="Error Page"
      description="Something went wrong..."
    >
      <h1>Error</h1>
      <h2>Page not found</h2>
      <p>The page you are trying to view does not exist. Either it never did exist, or it was removed. Please, continue to the <Link to='/'>homepage</Link>.</p>
    </Layout>
  );
};
